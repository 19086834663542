/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/image/youtube.png";

const NewsCard = ({ items }) => {
  const { title, image, date_stamp, _id, youtube_embed } = items;

  const dates = new Date(date_stamp); // convert it to a Date object
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dateInBengali = dates.toLocaleDateString("bn-IN", options); // convert the date to Bengali
  const [timeDifference, setTimeDifference] = useState("");

  useEffect(() => {
    const backendDate = new Date(date_stamp);

    const banglaDigits = ["০", "১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯"];

    const convertToBanglaDigits = (number) => {
      return number
        .toString()
        .split("")
        .map((digit) => banglaDigits[parseInt(digit)])
        .join("");
    };

    const calculateTimeDifference = () => {
      const now = new Date();
      const diffInMilliseconds = now - backendDate;

      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInDays < 10) {
        if (diffInDays > 0) {
          return `${convertToBanglaDigits(diffInDays)} দিন আগে`;
        } else if (diffInHours > 0) {
          return `${convertToBanglaDigits(diffInHours)} ঘন্টা আগে`;
        } else if (diffInMinutes > 0) {
          return `${convertToBanglaDigits(diffInMinutes)} মিনিট আগে`;
        } else {
          return `${convertToBanglaDigits(diffInSeconds)} সেকেন্ড আগে`;
        }
      } else {
        return `${dateInBengali}`;
      }
    };

    setTimeDifference(calculateTimeDifference());

    const intervalId = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, [dateInBengali, date_stamp]);

  var date = new Date(parseInt(_id));
  const moreButtonYear = date.getFullYear();

  return (
    <div>
      <div className=" max-w-72 my-2 bg-slate-200 shadow-xl  pb-1 rounded-lg mx-auto">
        <Link to={`/newsDetails/${_id}`}>
          <figure className="relative">
            <img
              src={`https://www.iskconbangla.com/img/${moreButtonYear}_news_sm/${image}`}
              alt="img"
              className="w-full rounded-lg h-48"
            />
            {youtube_embed && (
              <img
                src={img}
                alt=""
                className="w-8 h-8 absolute bottom-0 right-0"
              />
            )}
          </figure>
          <div className=" p-2">
            <p className=" text-black ">{title}</p>
            <p className=" text-gray-400 text-sm pt-2 ">{timeDifference}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NewsCard;
